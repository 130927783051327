// eslint-disable-line jsx-a11y/no-static-element-interactions
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState, useRef } from 'react';
import useSWR from 'swr';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Snackbar, ConfirmationModal, ProjectorShimmer, ShareScreen } from 'Components';
import AddIcon from '@material-ui/icons/Add';
import { AppContext } from 'Context';
import { Box, Typography, TextField, Button } from '@mui/material';
import ModalMaterial from '@mui/material/Modal';
import { axios, dataFetcher, endpoints, baseUrl } from 'Api';
import EditIcon from '@mui/icons-material/Edit';
import { Pathname } from 'Routes';
import { ReactComponent as UpIcon } from 'Assets/upIcon.svg';
import { ReactComponent as DownIcon } from 'Assets/downIcon.svg';
import { ReactComponent as SelectedIcon } from 'Assets/selectedIcon.svg';
import { ReactComponent as SelectIcon } from 'Assets/selectIcon.svg';
import { ReactComponent as SaveButton } from 'Assets/saveButton.svg';
import Spinner from 'Components/Spinner';
import { ViewerPage } from '../components/page';
import GroupMemberList from './components/groupMembers';
import styles from './index.module.css';

const defaultSnackbar = { isVisible: false, type: undefined, message: undefined };
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};
export const Groups = () => {
    // eslint-disable-next-line no-script-url
    window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const { userDetails, langText, setAppSnackbar } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const profileID = userDetails.myId;
  const [groups, setGroups] = useState(undefined);
  const [showMembers, setShowMember] = useState(null);
  const [groupMembers, setGroupMembers] = useState(undefined);
  const [groupVideos, setGroupVideos] = useState(undefined);
  const [groupAlbums, setGroupAlbums] = useState(undefined);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [isDeleted, setDeleting] = useState(false);
  const [videos, setVideos] = useState(undefined);
  const [albums, setAlbums] = useState(undefined);
  const [saveButtonClass, setSaveButtonClass] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedVideos, setSelectedVideos] = useState(undefined);
  const [selectedAlbums, setSelectedAlbums] = useState(undefined);
  const [openGroupModal, setGroupModal] = useState(false);
  const groupsText = langText?.viewersSideBar?.groups;
  const commonText = langText?.common;
  const spinerSmall = 'spinex';
  const { isValidating: loadingGroups, mutate: fetchGroups } = useSWR(endpoints.getMyGroups, {
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setGroups(data);
      } else {
        setGroups([]);
      }
    },
    onError: () => {
      setGroups([]);
    },
  });
  const { isValidating: loadingGroupMembers, mutate: fetchGroupMembers } = useSWR([
    endpoints.getMyGroupMembers, showMembers], {
    fetcher: (url, id) => dataFetcher(url, { id }),
    onSuccess: ({ success, data }) => {
      if (success && data) {
        setGroupMembers(data?.members);
      } else {
        setGroupMembers(undefined);
      }
    },
    onError: () => {
      setGroupMembers(undefined);
    },
  });

  const { isValidating: fetchingContent, mutate: fetchContent } = useSWR([endpoints.getVideosAndALbumsOfUser, profileID], {
    fetcher: (url, user_id) => dataFetcher(url, { user_id }),
    onSuccess: ({ success, album, video }) => {
      if (success) {
        setVideos(video);
        setAlbums(album);
      } else {
        setVideos(undefined);
        setAlbums(undefined);
      }
    },
    onError: () => {
      setVideos(undefined);
      setAlbums(undefined);
    },
  });

  const { isValidating: fetchingGroupContent, mutate: fetchGroupContent } = useSWR([
    endpoints.getVideosAndALbumsOfGroup, showMembers], {
    fetcher: (url, group_id) => dataFetcher(url, { group_id }),
    onSuccess: ({ success, album, video }) => {
      if (success) {
        setGroupVideos(video.map((item) => item.id));
        setGroupAlbums(album.map((item) => item.id));
      } else {
        setGroupVideos(undefined);
        setGroupAlbums(undefined);
      }
    },
    onError: () => {
      setGroupVideos(undefined);
      setGroupAlbums(undefined);
    },
  });

  const { isValidating: addingContentToGroup, mutate: addContentToGroup } = useSWR([
    endpoints.moveFilesToGroup, showMembers, selectedVideos, selectedAlbums], {
    fetcher: (url, group_id, video_id, album_id) => dataFetcher(url, { group_id, video_id, album_id }),
    onSuccess: ({ success, album, video }) => {
      if (success) {
        fetchGroupContent();
      }
    },
    onError: () => { },
  });

  useEffect(() => {
    fetchGroups();
    fetchContent();
  }, []);

  useEffect(() => {
    if (isDeleted) {
      const index = groups.findIndex((item) => item.id === showMembers);
      if (index !== -1) {
        groups.splice(index, 1);
      }
    }
  }, [isDeleted]);

  const addNewMembers = (id, title) => {
    navigate(Pathname.updatingGroup(id, title));
  };

  const showMemberDetails = (id) => {
    setShowMember(id);
  };

  useEffect(() => {
    if (showMembers) {
      fetchGroupMembers();
      fetchGroupContent();
    }
  }, [showMembers]);

  useEffect(() => {
    if (selectedVideos || selectedAlbums) {
      addContentToGroup();
    }
  }, [selectedVideos, selectedAlbums]);
  const addRemoveContent = (type, action, id) => {
    if (type === 'video') {
      // remove video from group
      if (action) {
        setGroupVideos(groupVideos?.filter((video) => video !== id));
      } else { // add video to group
        setGroupVideos(groupVideos ? [...groupVideos, id] : [id]);
      }
    } else if (action) {
      // remove album from group
      setGroupAlbums(groupAlbums?.filter((album) => album !== id));
    } else { // add album to group
      setGroupAlbums(groupAlbums ? [...groupAlbums, id] : [id]);
    }
  };

  const addPhotoandVideoToGroup = () => {
    setSelectedVideos(groupVideos ? groupVideos?.toString() : undefined);
    setSelectedAlbums(groupAlbums ? groupAlbums?.toString() : undefined);
  };
  const prevGroupVideosLength = useRef();
  const prevGroupAlbumsLength = useRef();
  useEffect(() => {
    if (groupVideos && groupAlbums) {
      // Set initial values only after data has been fetched
      if (prevGroupVideosLength.current === undefined) {
        prevGroupVideosLength.current = groupVideos?.length;
      }
      if (prevGroupAlbumsLength.current === undefined) {
        prevGroupAlbumsLength.current = groupAlbums?.length;
      }

      // Check if the lengths have changed compared to previous values
      const hasGroupVideosChanged = groupVideos?.length !== prevGroupVideosLength.current;
      const hasGroupAlbumsChanged = groupAlbums?.length !== prevGroupAlbumsLength.current;

      // Update button class if there is a change in length
      if (hasGroupVideosChanged || hasGroupAlbumsChanged) {
        setSaveButtonClass(true);
      } else {
        setSaveButtonClass(false);
      }
      // Update refs to current lengths for the next render
      prevGroupVideosLength.current = groupVideos?.length;
      prevGroupAlbumsLength.current = groupAlbums?.length;
    }
  }, [groupVideos, groupAlbums]);
  useEffect(() => {
    if ((openGroupModal && !location.search?.includes(Pathname.createGroup)) || !location.search?.includes(Pathname.updateGroup)) {
      fetchGroups();
      fetchContent();
      setShowMember(null);
    }
  }, [openGroupModal, location.search]);

  useEffect(() => {
    if (location.search?.includes(Pathname.createGroup)) {
      setGroupModal(true);
    }
  }, [location.search]);
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [editedGroupName, setEditedGroupName] = useState('');
  const [selectedOriginalName, setSelectedOriginalName] = useState('');
  const [editLoader, setEditLoader] = useState(false);
  console.log('editLoader', editLoader);

  const handleEditClick = (id, title) => {
    setSelectedOriginalName(title); // Store original name
    setSelectedGroup(id);
    setEditedGroupName(title);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedGroup(null);
    setEditedGroupName('');
  };
  const handleUpdate = async () => {
    setEditLoader(true);
    try {
      const response = await axios.post(`${baseUrl}${endpoints.addNewGroup}`, {
        group_id: selectedGroup,
        title: editedGroupName
      });
      console.log('Updated Group:', response.data);
      fetchGroups();
      fetchContent();
      handleClose();
      setEditLoader(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: response.data.message });
    } catch (error) {
      console.error('Update failed:', error);
      setEditLoader(false);
    }
  };
  return (
    <>
      <ViewerPage
        title={groupsText?.title || 'Groups'}
        addActionName={groupsText?.newGroup || 'New Group'}
        link={Pathname.createGroup}
      >
        <div className={styles.allGroupContainer}>
          {
            loadingGroups ? (
              <ProjectorShimmer isLight />
            ) : (
              groups?.map(({ id, title, members }, idx) => (
                <div className={showMembers === id ? styles.groupWrapper : ''}>
                  <span className={styles.groupTitle}>
                    {title}
                  </span>
                  {
                    showMembers === id
                      ? (
                        <>
                          <DownIcon
                            className={styles.expandIcon}
                            onClick={() => { setShowMember(null); }}
                          />
                          <span
                            aria-hidden="true"
                            className={styles.deleteGroup}
                            onClick={() => { setConfirmationModal(true); }}
                          >
                            {'Delete' || groupsText?.delete}
                          </span>
                          <span
                            aria-hidden="true"
                            className={styles.EditxGroup}
                            onClick={() => handleEditClick(id, title)}
                          >
Edit
                          </span>
                        </>
                      ) : (
                        <UpIcon
                          className={styles.expandIcon}
                          onClick={() => { showMemberDetails(id); setGroupName(title); }}
                        />
                      )
                  }
                  {
                    showMembers === id ? (
                      <div className={styles.memberContainer}>
                        <div className={styles.memberlist}>
                          <span
                            onClick={() => { addNewMembers(id, title); }}
                            className={styles.addMember}
                            aria-hidden="true"
                          >
                            <span className={styles.addMemberText}>Add group Members</span>
                            <AddIcon
                              // onClick={() => { addNewMembers(id, title); }}
                              fontSize="small"
                              className={styles.addIconStyle}
                            />
                          </span>
                          {
                            loadingGroupMembers ? (
                              <div>{`${commonText?.loading || 'Loading'}...`}</div>
                            ) : (
                              <div>
                                {groupMembers?.map(({ id, firstname, lastname, email, image }, idx) => (
                                  <GroupMemberList
                                    email={email}
                                    id={id}
                                    key={idx}
                                    firstname={firstname}
                                    lastname={lastname}
                                    setSnackbar={setSnackbar}
                                    group={showMembers}
                                    image={image}
                                  />
                                ))}
                              </div>
                            )
                          }
                        </div>
                        <div className={styles.videoList}>
                          {
                            fetchingContent || fetchingGroupContent ? (
                              <div>{`${commonText?.loading || 'Loading'}...`}</div>
                            ) : (
                              videos?.length > 0 ? (
                                <>
                                  <div className={styles.videoHeading}>{groupsText?.videos ? groupsText?.videos : 'Videos'}</div>
                                  <div className={styles.itemList}>
                                    {(videos?.map(({ id, title, lastname, email }, idx) => (
                                      <div>
                                        {
                                          groupVideos?.includes(id) ? (
                                            <span className={styles.selectIcon}>
                                              <SelectedIcon onClick={() => { addRemoveContent('video', true, id); }} />
                                            </span>

                                          ) : (
                                            <span className={styles.selectIcon}>
                                              <SelectIcon onClick={() => { addRemoveContent('video', false, id); }} />
                                            </span>
                                          )
                                        }
                                        <span className={styles.videoTitle}>{title}</span>
                                      </div>
                                    )))}
                                  </div>
                                </>
                              ) : null
                            )
                          }
                        </div>
                        <div className={styles.albumlist}>
                          {
                            fetchingContent || fetchingGroupContent ? (
                              <div>{`${commonText?.loading || 'Loading'}...`}</div>
                            ) : (
                              albums?.length > 0 ? (
                                <>
                                  <div className={styles.videoHeading}>{groupsText?.photoAlbum ? groupsText?.photoAlbum : 'Photo Albums'}</div>
                                  <div className={styles.itemList}>
                                    {(albums?.map(({ id, title, lastname, email }, idx) => (
                                      <div>
                                        {
                                          groupAlbums?.includes(id) ? (
                                            <span className={styles.selectIcon}>
                                              <SelectedIcon onClick={() => { addRemoveContent('album', true, id); }} />
                                            </span>

                                          ) : (
                                            <span className={styles.selectIcon}>
                                              <SelectIcon onClick={() => { addRemoveContent('album', false, id); }} />
                                            </span>
                                          )
                                        }
                                        <span className={styles.videoTitle}>{title}</span>
                                      </div>
                                    )))}
                                  </div>
                                </>
                              ) : null
                            )
                          }
                        </div>
                        <div className={styles.action}>
                          {/* <SaveButton className={saveButtonClass} onClick={saveButtonClass === styles.saveButton ? addPhotoandVideoToGroup : undefined} /> */}
<button disabled={!saveButtonClass || addingContentToGroup || fetchingGroupContent} className={styles.loaderButton} onClick={() => addPhotoandVideoToGroup()}>
  {!addingContentToGroup ? 'Save' : <Spinner spinex={spinerSmall} />}
</button>
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              ))
            )
          }
        </div>
      </ViewerPage>
      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || '.....'}
        onClose={() => { setSnackbar(defaultSnackbar); }}
        type={snackbar?.type}
      />
      {
        openConfirmationModal ? (
          <ConfirmationModal
            isVisible={openConfirmationModal}
            title={`Are you sure you want to delete Group ${groupName}`}
            onHide={() => { setConfirmationModal(false); }}
            cancelButton={commonText?.goBack || 'Go Back'}
            continueButton={commonText?.remove || 'Remove'}
            id={showMembers}
            name={groupName}
            setSnackbar={setSnackbar}
            removeType="group"
            setDeleting={setDeleting}
          />
        ) : null
      }
<ModalMaterial open={open} onClose={handleClose}>
<Box sx={{ ...style, fontFamily: 'Poppins, sans-serif' }}>
          <Typography variant="h6" sx={{ fontFamily: 'Poppins, sans-serif' }}>
            Edit the Group Name
          </Typography>

          {/* Input Field */}
          <TextField
            autoFocus
            variant="standard"
            fullWidth
            placeholder="Enter Group Name"
            value={editedGroupName}
            onChange={(e) => setEditedGroupName(e.target.value)}
            InputProps={{
              disableUnderline: false,
              sx: { fontFamily: 'Poppins, sans-serif', '&:after': { borderBottomColor: '#5aa5ef' } },
            }}
            sx={{ mt: 2, fontFamily: 'Poppins, sans-serif' }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleUpdate}
              disabled={!editedGroupName.trim() || editedGroupName === selectedOriginalName || editLoader} // Disable if empty or unchanged
              sx={{
                bgcolor: '#5aa5ef', // Keep background color unchanged
                color: 'white', // Keep text color unchanged
                fontFamily: 'Poppins, sans-serif',
                textTransform: 'capitalize',
                fontWeight: 500,
                fontSize: '14px',
                boxShadow: 'none',
                opacity: 1, // Ensures opacity is not changed by default styles
                '&.Mui-disabled': {
                  fontWeight: 500,
                  fontSize: '14px',
                  opacity: 0.5, // Reduce opacity only
                  cursor: 'not-allowed',
                  boxShadow: 'none',
                  bgcolor: '#5aa5ef', // Keep same background when disabled
                  color: 'white', // Keep text color when disabled
                  fontFamily: 'Poppins, sans-serif',
                  textTransform: 'capitalize',
                }
              }}
            >
{editLoader ? (
  <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
    Updating
{' '}
<Spinner spinex={spinerSmall} />
  </span>
) : 'Update'}

            </Button>
          </Box>
</Box>
</ModalMaterial>
    </>
  );
};
